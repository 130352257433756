<template>
  <div class="props__block props__block_v2 oblique oblique_2">
    <div class="container">
      <div class="props props_v2">
        <div class="props__item props__item_left props__item-img-1">
          <img
            src="@/assets/img/bg/bg-partners-2.png"
            width="683"
            height="388"
            loading="lazy"
            alt=""
          />
        </div>

        <div class="props__item props__item_right props__item-text-1">
          <div class="attention-block attention-block_long">
            <h2 class="page-title page-title_2">
              {{ $getTranslate('partners_props_title_1') }}
            </h2>

            {{ $getTranslate('partners_props_text_1') }}
          </div>
        </div>

        <div class="props__item props__item_left props__item-text-2">
          <div class="attention-block attention-block_long">
            <h2 class="page-title page-title_2">
              {{ $getTranslate('partners_props_title_2') }}
            </h2>

            {{ $getTranslate('partners_props_text_2') }}
          </div>
        </div>

        <div class="props__item props__item_right props__item-img-2">
          <img
            src="@/assets/img/bg/bg-partners-3.png"
            width="518"
            height="388"
            loading="lazy"
            alt=""
          />
        </div>

        <div class="props__item props__item_left props__item-img-3">
          <img
            src="@/assets/img/bg/bg-partners-4.png"
            width="573"
            height="388"
            loading="lazy"
            alt=""
          />
        </div>

        <div class="props__item props__item_right props__item-text-3">
          <div class="attention-block attention-block_long">
            <h2 class="page-title page-title_2">
              {{ $getTranslate('partners_props_title_3') }}
            </h2>

            {{ $getTranslate('partners_props_text_3') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PropsBlockPartners'
}
</script>
