<template>
  <div>
    <div class="container">
      <div class="top-block">
        <div class="top-block__img">
          <img
            src="@/assets/img/bg/bg-partners-1.png"
            alt=""
            loading="lazy"
            width="520"
            height="300"
          />
        </div>

        <AttentionBlock
          h1
          :title="$getTranslate(topBlock.title)"
          :text="$getTranslate(topBlock.text)"
        />
      </div>
    </div>

    <PropsBlockPartners />

    <div class="color-blocks">
      <ColorBlock :item="colorBlockPartners1" />
      <ColorBlock id="referal" :item="colorBlockPartners2" />
    </div>
  </div>
</template>

<script>
import PropsBlockPartners from '@/components/PropsBlockPartners.vue'

export default {
  name: 'PartnersPage',
  components: {
    PropsBlockPartners
  },

  metaInfo() {
    return {
      title: this.$getTranslate('meta_partners_title'),
      meta: [
        {
          name: 'description',
          content: this.$getTranslate('meta_partners_description')
        },
        {
          property: 'og:title',
          content: this.$getTranslate('meta_partners_title')
        },
        { property: 'og:site_name', content: 'Costaction' },
        {
          property: 'og:image',
          content:
            window.location.href + require('@/assets/img/bg/bg-partners-1.png')
        },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },

  data() {
    return {
      topBlock: {
        h1: true,
        title: 'top_title_partners',
        text: 'top_text_partners'
      },

      colorBlockPartners1: {
        bg: 'circles',
        title: 'color_block_title_partners_1',
        text: 'color_block_text_partners_1'
      },

      colorBlockPartners2: {
        red: true,
        bg: 'squares',
        title: 'color_block_title_partners_2',
        text: 'color_block_text_partners_2'
      }
    }
  }
}
</script>
